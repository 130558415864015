"use client";

import ReactPlayer from "react-player";

// import dynamic from "next/dynamic";
// const ReactPlayer = dynamic(() => import("react-player/lazy"), {
//   ssr: false,
// });

export const VideoPlayer = ({ url }) => {
  return (
    <div className="relative w-full pb-[56.25%]">
      <ReactPlayer
        url={url}
        playing={true}
        width="100%"
        height="100%"
        style={{ position: `absolute`, top: 0, left: 0 }}
      />
    </div>
  );
};

// export const BackgroundVideo = ({ videoUrl, previewImage }) => {
//   const [state, setState] = useState({
//     playing: true,
//     loop: true,
//     volume: 0,
//     muted: true,
//   });

//   if (!videoUrl) return null;

//   return (
//     <Box
//       sx={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: "100%",
//         height: "100%",
//         paddingTop: "56.25%",
//         "&:after": {
//           content: '""',
//           position: "absolute",
//           top: "0",
//           left: "0",
//           zIndex: 1,
//           width: "100%",
//           height: "100%",
//           backgroundImage:
//             "linear-gradient(rgba(66, 92, 187, 0.7), rgba(66, 92, 187, 0.7))",
//         },
//       }}
//     >
//       <Box
//         as={ReactPlayer}
//         url={videoUrl}
//         playing={state.playing}
//         loop={state.loop}
//         volume={state.volume}
//         muted={state.muted}
//         // light={previewImage || true}
//         width="100%"
//         height="100%"
//         playsinline={true}
//         playIcon={<span />}
//         sx={{
//           position: "absolute",
//           top: "0",
//           left: "0",
//           width: "100% !important",
//           height: "100% !important",
//           overflow: "hidden !important",
//           pointerEvents: "none",
//           "@media (min-aspect-ratio: 16/9)": {
//             top: "-100%",
//             height: "300% !important",
//           },
//           "@media (max-aspect-ratio: 16/9)": {
//             left: "-100%",
//             width: "300% !important",
//           },
//           ".play-button": {
//             width: "12%",
//             borderRadius: "50%",
//             transition: "transform 500ms ease, border 200ms ease",
//             "&:hover": { transform: "scale(1.1)", border: "2px solid green" },
//           },
//         }}
//       />
//     </Box>
//   );
// }
