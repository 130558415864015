import Link from "next/link";
import parse from "html-react-parser";
import { isEmpty } from "lodash";
import { cn } from "@/lib/utils";
import { Button } from "@/components/button";
import { VideoButton } from "@/components/video-button";

export const Hero = ({
  className,
  children,
  // backgroundVideoUrl,
  backgroundImage,
  title,
  subtitle,
  buttons,
  variant,
  overlay,
  size,
}) => {
  const backgroundImageStack = backgroundImage
    ? [
        backgroundImage ? `url(${backgroundImage})` : null,
        overlay
          ? `linear-gradient(rgba(66, 92, 187, 0.7), rgba(66, 92, 187, 0.7))`
          : `linear-gradient(rgba(66, 92, 187, 0), rgba(66, 92, 187, 0))`,
      ].reverse()
    : [];

  return (
    <div className={cn("relative px-5 bg-blue-600", className)}>
      <div
        className="absolute top-0 left-0 w-full h-full bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: backgroundImageStack }}
      ></div>
      {/* <BackgroundVideo
        videoUrl={backgroundVideoUrl}
        previewImage={backgroundImage ? backgroundImage : null}
      /> */}
      <div
        className={cn(
          "relative max-w-6xl mx-auto",
          size === "sm" ? "py-20" : "py-24 md:py-36 lg:py-44"
        )}
      >
        <div
          className={cn(
            "text-white",
            variant === "offset"
              ? "text-left max-w-2xl mx-0"
              : "text-center max-w-3xl mx-auto"
          )}
        >
          {title && <h1 className="text-inherit mb-2">{parse(title)}</h1>}
          {subtitle && (
            <div className="text-inherit my-2 text-lg">{parse(subtitle)}</div>
          )}
          {!isEmpty(buttons) && (
            <div className="flex flex-wrap gap-x-6 gap-y-4 mt-10">
              {buttons?.map(({ type, ...button }, index) => {
                if (!button[type]?.url) return null;

                if (type === "video") {
                  return (
                    <VideoButton
                      key={index}
                      variant={index === 0 ? "ghost" : "secondary"}
                      url={button[type].url}
                    >
                      {button[type].title}
                    </VideoButton>
                  );
                }

                return (
                  <Button
                    key={index}
                    variant={index === 0 ? "ghost" : "secondary"}
                    asChild
                  >
                    <Link href={button[type].url}>{button[type].title}</Link>
                  </Button>
                );
              })}
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
