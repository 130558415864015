export const BinderTag = ({ status }) => {
  switch (status) {
    case "new":
      return (
        <span className="absolute -right-2 -bottom-2 font-semibold inline-flex items-center rounded-md bg-yellow-500 px-2 py-0.5 text-xs text-gray-800 ring-1 ring-inset ring-gray-500/10">
          NEW
        </span>
      );
    case "updated":
      return (
        <span className="absolute -right-2 -bottom-2 font-semibold inline-flex items-center rounded-md bg-yellow-400 px-2 py-0.5 text-xs text-gray-800 ring-1 ring-inset ring-gray-500/10">
          UPDATED
        </span>
      );
    default:
      return null;
  }
};
