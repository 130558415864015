"use client";

import { Button } from "@/components/button";
import { Modal } from "@/components/modal";
import { VideoPlayer } from "@/components/video-player";

export const VideoButton = ({ children, url, ...props }) => {
  return (
    <Modal
      trigger={({ setOpen }) => (
        <Button onClick={() => setOpen(true)} {...props}>
          {children}
        </Button>
      )}
    >
      <VideoPlayer url={url} />
    </Modal>
  );
};
