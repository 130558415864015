"use client";

import { useState, useEffect, useId } from "react";
import Image from "next/image";
import { cn, isValidHttpUrl } from "@/lib/utils";
import binderIcon from "@/images/binder-icon.png";
import binderImageFallback from "@/images/binder-fallback.png";

export const BinderImage = ({ className, children, src, alt }) => {
  const [error, setError] = useState(null);
  const id = useId();

  useEffect(() => {
    setError(null);
  }, [src]);

  if (!isValidHttpUrl(src)) {
    src = `${process.env.NEXT_PUBLIC_RAILS_PROTOCOL}://${process.env.NEXT_PUBLIC_RAILS_DOMAIN}${src}`;
  }

  return (
    <div
      className={cn(
        "relative flex justify-center items-center w-full m-[0 auto 15px] after:content[''] after:block after:pb-[100%]",
        className
      )}
    >
      <Image
        src={binderIcon.src}
        alt={`Binder icon ${id}`}
        fill
        sizes="300px"
      />
      <Image
        unoptimized
        className="!top-auto !right-0.5 !bottom-0 !left-auto !w-[90%] !h-[90%] p-1.5 object-cover"
        src={error ? binderImageFallback.src : src}
        alt={alt}
        fill
        sizes="300px"
        onError={setError}
      />
      {children}
    </div>
  );
};
